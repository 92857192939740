.about-us-list-container {
  display: flex;
  justify-content: center;
}

.about-us-list-wrap {
  display: grid;
  width: 100%;
  padding: 0px 50px;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
}

.about-us-item-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.3s;
}

.about-us-list-wrap:hover .about-us-item-wrap {
  transform: scale(0.98);
}

.about-us-item-wrap:hover {
  transform: scale(1.02) !important;
}

.about-us-item-wrap img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.about-us-item-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
  padding: 50px 50px;
}

.about-us-content-top-bar {
  padding: 5px 40px;
  background-color: var(--primary-color);
  border-radius: 31.53px;
  margin-bottom: 6.688rem;
}

.about-us-content-wrap {
  text-align: left;
  flex-grow: 1;
}

.about-us-content-title {
  color: var(--primary-color);
  font-size: var(--h5-size);
  font-weight: bold;
}

.about-us-content-line {
  color: var(--primary-color);
  height: 5px;
}

.about-us-content-text {
  font-size: var(--h7-size);
  line-height: 1.5;
  word-break: keep-all;
  flex-grow: 1;
  overflow-y: auto;
}

.about-us-list-mobile-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.about-us-list-mobile-wrap {
  width: 80%;
  padding: 0 20px;
  overflow: hidden;
  position: relative;
}

/* 슬라이더 기본 스타일 */
.about-us-list-mobile-wrap .slick-slider {
  width: 100%;
  margin: 0 10px;
}

.about-us-list-mobile-wrap .slick-slide {
  padding: 0 10px; /* 슬라이드 간격 */
}

.about-us-list-mobile-wrap .slick-list {
  overflow: visible; /* 다음 슬라이드가 보이도록 설정 */
  margin: 0 -10px; /* 패딩 상쇄 */
}

/* 활성 슬라이드 스타일링 */
.about-us-list-mobile-wrap .slick-slide {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.about-us-list-mobile-wrap .slick-current {
  opacity: 1;
}

/* 슬라이더 트랙 스타일링 */
.about-us-list-mobile-wrap .slick-track {
  display: flex;
  gap: 20px; /* 슬라이드 사이 간격 */
}

/* 슬라이드 트랙 스타일링 */
.about-us-list-mobile-wrap .slick-track {
  display: flex;
  align-items: stretch;
}

.about-us-list-mobile-wrap .slick-slide {
  height: auto;
}

.about-us-list-mobile-wrap .slick-slide > div {
  height: 100%;
}

@media (max-width: 992px) {
  .about-us-list-container {
    width: 100%;
  }

  .about-us-content-top-bar {
    margin-bottom: 3.688rem;
  }

  .about-us-item-content {
    padding: 50px 20px;
  }

  .about-us-list-wrap {
    padding: 0px 0px;
  }

  .about-us-content-title {
    font-size: var(--h7-size);
  }
  .about-us-content-text {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .about-us-item-content {
    padding: 50px 100px;
  }

  .about-us-content-title {
    font-size: var(--h4-size);
  }

  .about-us-content-line {
    height: 10px;
  }

  .about-us-content-text {
    font-size: var(--h6-size);
  }
}
@media (max-width: 680px) {
  .about-us-item-content {
    padding: 50px 50px;
  }

  .about-us-content-title {
    font-size: var(--h5-size);
  }

  .about-us-content-line {
    height: 5px;
  }

  .about-us-content-text {
    font-size: var(--h7-size);
  }

  .about-us-list-mobile-wrap {
    width: 100%;
  }

  .about-us-content-top-bar {
    padding: 5px 20px;
    margin-bottom: 1.688rem;
  }
}

@media (max-width: 375px) {
  .about-us-item-content {
    padding: 50px 25px;
  }
}
