.facilities-wrapper {
  background-color: #000;
  color: white;
  min-height: 100vh;
  width: 100%;
  padding: 1px 0;
}

.facilities-backgroud {
  height: 100%;
  background-image: linear-gradient(
    0deg,
    var(--box-color) 10%,
    transparent 60%
  );
  z-index: 0;
}

.facilities-wrap {
  padding: 100px 0px;
  width: 100%;
}

.facilities-topic {
  font-size: var(--h4-size);
  font-weight: bold;
  color: var(--primary-color);
}

.facilities-tilte {
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  font-size: var(--h2-size);
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 37px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.amenities-text {
  font-size: var(--h5-size);
  text-align: center;
  width: 100%;
  padding: 10px 0;
}

.facilities-title-sub {
  font-size: var(--h6-size);
  font-weight: bold;
  color: #606060;
  margin-left: 31px;
  margin-bottom: 0px;
}

.facilities-content {
  font-size: var(--h6-size);
  font-weight: 500;
  line-height: 200%;
  padding-bottom: 32px;
  word-break: keep-all;
}

.facilities-pros-section {
  text-align: center;
}

.facilities-pros-title-deco {
  font-size: var(--h4-size);
  font-weight: bold;
  color: #fff;
}

.facilities-pros-title {
  font-size: var(--h2-size);
  font-weight: bold;
  color: var(--primary-color);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.facilities-pros-wrap {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  padding: 76px 150px;
  gap: 29px;
}

.facilities-pros-item {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.facilities-pros-item:hover {
  transition: all 0.3s;
  transform: scale(1.03);
}

.facilities-pros-item img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.facilities-pros-item-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  z-index: 1;
}

.circle {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 29px;
}

.facilities-pros-item-text p {
  text-align: center;
  margin: 0;
  color: white;
  /* 텍스트 색상 */
  font-size: var(--h4-size);
  font-weight: bold;
  line-height: 146%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.amenities-warp {
  padding: 100px 0px;
  display: flex;
  flex-direction: column;
}

.amenities-item-wrap {
  display: flex;
  justify-content: center;
}

.amenities-list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 31px;
}

.amenities-item {
  display: flex;
  width: 80%;
  height: 80%;
  /* width: 145px; */
  /* height: 145px; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.amenities-item:hover {
  transition: all 0.3s;
  transform: scale(1.1);
}

.amenities-icon {
  width: 100%;
  flex-grow: 1;
  height: auto;
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--box-color);
}

.amenities-icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.map-section {
  background-color: var(--box-color);
  padding: 100px 0px;
  margin-bottom: 50px;
}

.map-wrap {
  text-align: center;
  padding: 0px 170px;
}

.map-div {
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 70px;
}

.name-card {
  border-left: 5px solid #fff;
  text-align: left;
  padding: 5px 36px;
}

.name-card-title {
  color: var(--primary-color);
  font-size: var(--h4-size);
  font-weight: bold;
}

.name-card-content {
  font-size: var(--h5-size);
  font-weight: bold;
}

@media (max-width: 1200px) {
  .facilities-pros-item-text p {
    font-size: var(--h5-size);
  }

  .facilities-pros-title {
    font-size: var(--h3-size);
  }
  .facilities-pros-title-deco {
    font-size: var(--h5-size);
  }

  .circle {
    width: 25px;
    height: 25px;
  }

  .amenities-list {
    gap: 15px;
  }
  .map-wrap {
    padding: 0px 100px;
  }
}
@media (max-width: 992px) {
  .facilities-pros-wrap {
    padding: 70px 90px;
  }

  .facilities-topic {
    font-size: var(--h5-size);
  }

  .facilities-tilte {
    font-size: var(--h3-size);
  }

  .map-wrap {
    padding: 0px 50px;
  }
}
@media (max-width: 768px) {
  .facilities-wrap {
    padding: 100px 50px;
  }

  .facilities-pros-wrap {
    padding: 70px 45px;
  }
  .amenities-list {
    gap: 0px;
  }

  .name-card-title {
    font-size: var(--h5-size);
  }
  .name-card-content {
    font-size: var(--h6-size);
  }

  .facilities-tilte {
    align-items: start;
    flex-direction: column;
  }

  .facilities-title-sub {
    margin-top: 5px;
    margin-left: 0px;
  }

  .amenities-icon {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .facilities-topic {
    font-size: var(--h6-size);
  }

  .facilities-tilte {
    font-size: var(--h5-size);
  }
  .facilities-title-sub {
    font-size: var(--h7-size);
  }

  .facilities-content {
    font-size: var(--h7-size);
  }
  .facilities-wrap {
    padding: 100px 25px;
  }

  .facilities-pros-wrap {
    padding: 70px 25px;
  }
  .facilities-pros-title {
    font-size: var(--h5-size);
  }
  .facilities-pros-title-deco {
    font-size: var(--h7-size);
  }
  .amenities-item {
    width: 50%;
    height: 50%;
  }

  .amenities-list {
    gap: 30px 0px;
  }

  .amenities-text {
    font-size: var(--h7-size);
  }

  .amenities-icon {
    padding: 25px;
  }

  .circle {
    width: 15px;
    height: 15px;
    font-size: 10px;
  }
  .facilities-pros-item-text p {
    font-size: var(--h7-size);
  }

  .map-wrap {
    padding: 0px 10px;
  }

  .name-card-title {
    font-size: var(--h6-size);
  }
  .name-card-content {
    font-size: var(--h7-size);
  }
}
