.brand-wrapper {
  background-color: #000;
  color: white;
  min-height: 100vh;
  width: 100%;
  padding: 1px 0px;
}

.brand-backgroud {
  height: 100%;
  background-image: linear-gradient(
    0deg,
    var(--box-color) 10%,
    transparent 60%
  );
  z-index: 0;
  padding: 200px 0px;
}

.our-story-section {
  text-align: center;
  margin-bottom: 6.125rem;
}

.our-story-topic {
  color: var(--primary-color);
  font-size: var(--h4-size);
  font-weight: bold;
  margin-bottom: 30px;
}

.our-story-title {
  font-size: var(--h2-size);
  font-weight: bold;
  margin-bottom: 48px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.our-story-content {
  font-size: var(--h6-size);
  font-weight: bold;
  color: #a6a6a6;
  word-break: keep-all;
}

.brand-arrow img {
  height: 8.938rem;
  width: auto;
  margin-bottom: 6.875rem;
}

.about-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 25.938rem;
}

.about-us-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  max-width: 1600px;
}

.about-us-topic {
  color: var(--primary-color);
  font-size: var(--h4-size);
  font-weight: bold;
  margin-bottom: 30px;
}

.about-us-title {
  font-size: var(--h2-size);
  font-weight: bold;
  margin-bottom: 108px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.for-you-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.for-you-title {
  font-size: var(--h2-size);
  font-weight: bold;
  margin-bottom: 43px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.for-you-topic {
  color: var(--primary-color);
  font-size: var(--h3-size);
  font-weight: bold;
  margin-bottom: 141px;
}

/* 대각으로 짜른 div */
.for-you-content-container {
  width: 70%;
  height: auto;
}

.for-you-content-wrap {
  width: 100%;
  height: 70vh;
  position: relative;
  background: #000;
  overflow: hidden;
}

.left-content-img-wrap {
  position: absolute;
  width: 30%;
  left: 7%;
  bottom: 10%;
}

.left-content-img-wrap img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.left-content-text {
  position: absolute;
  left: 27%;
  top: 38%;
  font-size: var(--h3-size);
  font-weight: bold;
  color: var(--primary-color);
}

.right-content-img-wrap {
  position: absolute;
  width: 30%;
  right: 7%;
  bottom: 10%;
}

.right-content-img-wrap img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.right-content-text {
  position: absolute;
  right: 27%;
  top: 38%;
  font-size: var(--h3-size);
  font-weight: bold;
  color: var(--primary-color);
}

.diagonal-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.left-content,
.right-content {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  transition: background-color 0.3s ease;
}

.left-content,
.right-content:hover {
  cursor: pointer;
}

.left-content {
  position: absolute;
  left: 0;
  background-color: #000000;
  clip-path: polygon(0 0, 55% 0, 45% 100%, 0 100%);
}

.right-content {
  position: absolute;
  right: 0;
  background-color: #000000;
  clip-path: polygon(55% 0, 100% 0, 100% 100%, 45% 100%);
}

/* 가운데 노란 선 */
.diagonal-container::after {
  content: "";
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 10%;
  background-color: yellow;
  clip-path: polygon(
    calc(54% - 2px) 0,
    calc(54% + 2px) 0,
    calc(46% + 2px) 100%,
    calc(46% - 2px) 100%
  );
  z-index: 1;
}

/* 오른쪽 hover 시 */
.left-content.right-hovered {
  background-image: url("../assets/images/brand/for_you-right-backgroud.png");
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
}

.left-original-content,
.left-hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  /* hover 애니메이션 속도 조절*/
}

.left-original-content {
  transform: translateX(0);
}

.left-hover-content {
  transform: translateX(-100%);
}

.left-hover-content-wrap {
  max-width: 100%;
  width: 50%;
  position: absolute;
  right: 55%;
  top: 40%;
}

.left-hover-text-title {
  font-size: var(--h5-size);
  font-weight: bold;
  line-height: 146%;
  color: var(--primary-color);
}

.left-hover-text-content {
  font-size: var(--h7-size);
  line-height: 200%;
}

.left-hover-text-footer {
  color: #c9ad4b;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
}

.left-original-content.slide-out {
  transform: translateX(100%);
}

.left-hover-content.slide-in {
  transform: translateX(0);
}

/* 왼쪽 hover 시 */
.right-content.left-hovered {
  background-image: url("../assets/images/brand/for_you-left-backgroud.png");
  background-size: auto 100%;
  background-position: right center;
  background-repeat: no-repeat;
}

.right-original-content,
.right-hover-content {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  /* hover 애니메이션 속도 조절*/
}

.right-original-content {
  transform: translateX(0);
}

.right-hover-content {
  transform: translateX(100%);
}

.right-hover-content-wrap {
  max-width: 100%;
  width: 50%;
  position: absolute;
  left: 55%;
  top: 40%;
}

.right-hover-text-title {
  font-size: var(--h5-size);
  font-weight: bold;
  line-height: 146%;
  color: var(--primary-color);
}

.right-hover-text-content {
  font-size: var(--h7-size);
  line-height: 200%;
}

.right-hover-text-footer {
  color: #c9ad4b;
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
}

.right-original-content.slide-out {
  transform: translateX(-100%);
}

.right-hover-content.slide-in {
  transform: translateX(0);
}

/* 왼쪽 hover 마무리 */

.mission-vision-warp {
  padding: 100px 100px;
  text-align: center;
}

.mission-vision-title {
  font-size: var(--h2-size);
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 80px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.mission-vision-content-wrap {
  display: flex;
  justify-content: center;
}

.mission-vision-content {
  text-align: left;
  font-size: var(--h2-size);
  line-height: 150%;
  font-weight: 800;
  -webkit-text-stroke-width: 0.3px;
  -webkit-text-stroke-color: white;
  -webkit-text-fill-color: black;
}

.mission-vision-content-white {
  -webkit-text-stroke-color: white;
  -webkit-text-fill-color: white;
}

.mission-vision-content-gray {
  -webkit-text-stroke-color: gray;
  -webkit-text-fill-color: gray;
}

.promise-section {
  padding: 100px 0px 50px 0px;
}

.promise-warp {
  width: 100%;
  height: auto;
  padding: 100px 0px;
  background-image: url("../assets/images/brand/promise-backgroud.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}

.promise-title {
  font-size: var(--h3-size);
  font-weight: bold;
  color: var(--primary-color);
  margin: 50px 0px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.promise-content {
  font-size: var(--h3-size);
  font-weight: bold;
  line-height: 1.6;
  margin-bottom: 50px;
}

@media (max-width: 1200px) {
  .right-content-text {
    right: 20%;
  }
  .promise-content {
    font-size: var(--h4-size);
  }
  .for-you-content-wrap {
    height: 70vh;
  }
  .about-us-wrap {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .right-content-text {
    right: 13%;
  }
  .for-you-content-wrap {
    height: 60vh;
  }

  .about-us-wrap {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .our-story-topic {
    font-size: var(--h5-size);
  }

  .our-story-title {
    font-size: var(--h3-size);
  }

  .our-story-content {
    font-size: var(--h7-size);
    padding: 0px 20px;
  }

  .for-you-content-wrap {
    height: 50vh;
  }
  .about-us-content-text {
    line-height: 1.8;
  }

  .left-content-text {
    left: 20%;
  }

  .right-hover-text-title,
  .left-hover-text-title {
    font-size: var(--h6-size);
  }
  .right-hover-text-content,
  .left-hover-text-content {
    font-size: 10px;
  }

  .mission-vision-content {
    font-size: var(--h4-size);
  }

  .mission-vision-warp {
    padding: 100px 20px;
  }

  .promise-content {
    font-size: var(--h5-size);
  }

  .right-content-text,
  .left-content-text {
    font-size: var(--h6-size);
  }
}

@media (max-width: 576px) {
  .our-story-title,
  .about-us-title,
  .for-you-title,
  .mission-vision-title {
    font-size: var(--h5-size);
  }

  .our-story-content {
    font-size: 9px;
  }

  .about-us-section {
    margin-bottom: 15.938rem;
  }

  .for-you-content-wrap {
    height: 30vh;
  }

  .promise-title {
    font-size: var(--h6-size);
  }

  .our-story-topic,
  .about-us-topic,
  .for-you-topic {
    font-size: var(--h6-size);
  }

  .mission-vision-content {
    font-size: var(--h6-size);
  }

  .mission-vision-warp {
    padding: 100px 20px;
  }

  .promise-content {
    font-size: var(--h7-size);
  }

  .right-content-text,
  .left-content-text {
    font-size: var(--h6-size);
  }

  .right-hover-text-title,
  .left-hover-text-title {
    font-size: 8px;
  }
  .right-hover-text-content,
  .left-hover-text-content {
    font-size: 6px;
  }
}
