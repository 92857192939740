/* ProgramSlider.css */
.program-slider-container {
  padding: 100px 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.program-slider-content-wrap {
  width: 40%;
  /* 필요한 경우 스타일 추가 */
}

.program-slider-bar {
  padding: 3px 0px;
  width: 20%;
  margin-bottom: 15px;
  background-color: var(--primary-color);
  border-radius: 10px;
}

.program-slider-title {
  font-size: var(--h2-size);
  font-weight: bold;
  margin-bottom: 56px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  word-break: keep-all;
}

.program-slider-description {
  font-size: var(--h6-size);
  line-height: 200%;
  height: auto;
  margin-bottom: 50px;
  color: #a6a6a6;
  word-break: keep-all;
}

.program-slider-progress-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.program-slider-arrows-container {
  display: flex;
  gap: 10px;
}

.program-slider-wrapper {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center;
  /* 세로 중앙 정렬 */
  height: 100%;
  /* 부모 높이만큼 설정 */
}

.program-styled-slider {
  margin-left: 0 !important;
  width: 100%;
  display: flex;
  align-items: center;
}

.program-styled-slider .slick-slide {
  padding: 0 10px;
  opacity: 0.5;
  border-radius: 10px;
  transform: scale(0.9);
  transition: all 0.5s;
  transition-delay: 0s;
}

.program-styled-slider .slick-current {
  opacity: 1;
  transform: scale(1);
  border-radius: 10px;
  transition: all 0.5s;
  transition-delay: 0.5s;
  margin-left: 0;
}

.program-right-slide-image {
  width: 300px;
  aspect-ratio: 3/4;
  border-radius: 10px;
  object-fit: cover;
  position: relative;
  left: 35%;
}

.program-left-slide-image {
  width: 300px;
  aspect-ratio: 3/4;
  border-radius: 10px;
  object-fit: cover;
  position: relative;
  right: -65%;
}

.styled-slider .slick-slide:not(.slick-current) {
  transform: scale(0.7);
  transition: transform 0.3s ease;
}

.program-arrow-button {
  top: 50%;
  z-index: 1;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #ffffff00;
}

.program-arrow-button svg path {
  stroke: #a6a6a6;
  transition: stroke 0.3s ease;
}

.program-arrow-button:hover svg path {
  stroke: #1162a2;
  /* hover 시 변경될 색상 */
}

.program-right-progress-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
}

.program-left-progress-bar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: end;
}

.program-progress-dot {
  width: 30%;
  height: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.program-progress-dot.active {
  background-color: #1162a2;
}

.program-progress-dot:not(.active) {
  background-color: #a6a6a6;
}

@media (max-width: 992px) {
  .program-slider-title {
    font-size: var(--h5-size);
  }

  .program-slider-description {
    font-size: var(--h7-size);
  }
}

@media (max-width: 768px) {
  .program-slider-container {
    flex-direction: column;
  }

  .program-slider-title {
    font-size: var(--h3-size);
  }

  .program-slider-description {
    font-size: var(--h5-size);
  }

  .program-slider-progress-navigation {
    width: 100%;
  }

  .program-slider-progress-navigation {
    width: 80%;
  }
  .program-slider-arrows-container {
    width: 20%;
  }
  .program-slider-content-wrap {
    width: 100%;
  }

  .program-slider-wrapper {
    width: 100%;
  }
}

@media (max-width: 552px) {
  .program-slider-title {
    font-size: var(--h5-size);
    margin-bottom: 20px;
  }

  .program-slider-description {
    font-size: var(--h7-size);
    margin-bottom: 0px;
  }
}
