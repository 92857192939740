.anthor-program-slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 100px;
}

.anthor-program-styled-slider {
  width: 100%;
  display: flex;
  align-items: center;
}

.anthor-program-slider-wrapper {
  position: relative;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.anthor-program-slider-wrapper:hover {
  transform: scale(1.05);
}

.anthor-program-slider-content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.anthor-program-slide-image {
  margin: 0 auto;
  display: block;
  width: 50%;
  height: auto;
  margin-bottom: 34px;
}

.anthor-program-slider-description {
  font-size: var(--h6-size);
  font-weight: 500;
  line-height: 200%;
  word-break: keep-all;
}

.anthor-program-slider-progress-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.anthor-program-progress-bar {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.anthor-program-progress-dot {
  margin: 0px 10px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.anthor-program-progress-dot svg {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
}

.anthor-program-progress-dot.active svg {
  transform: scale(1.1);
}

.progress-icon {
  width: 80px;
  height: 80px;
  transition: all 0.3s ease;
}

.progress-icon .anthor-program-nav-background {
  fill: var(--box-color);
}

.progress-icon .anthor-program-nav-line {
  fill: var(--primary-color);
}

.progress-icon.active {
  filter: none;
}

.progress-icon.active .anthor-program-nav-background {
  fill: var(--primary-color);
}

.progress-icon.active .anthor-program-nav-line {
  fill: var(--box-color);
}

.progress-icon-text {
  color: var(--primary-color);
  font-size: var(--h6-size);
  font-weight: bold;
  letter-spacing: -3%;
}

@media (max-width: 1200px) {
  .anthor-program-slide-image {
    width: 100%;
  }

  .anthor-program-slider-description {
    font-size: var(--h6-size);
  }

  .anthor-program-slider-wrapper {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .anthor-program-progress-bar {
    width: 80%;
  }
  .progress-icon-text {
    font-size: var(--h7-size);
  }

  .anthor-program-slider-description {
    font-size: var(--h6-size);
  }

  .anthor-program-slider-wrapper {
    width: 90%;
  }
}

@media (max-width: 552px) {
  .anthor-program-slider-description {
    font-size: 9px;
  }

  .progress-icon-text {
    margin: 0px;
  }
}

@media (max-width: 320px) {
  .anthor-program-progress-bar {
    width: 100%;
  }
}
