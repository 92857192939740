.hero-section {
  padding: 150px 200px;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
}

.hero-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.hero-logo {
  width: 50%;
  height: auto;
  text-align: right;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
}

.hero-logo img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.text-flicker-in-glow {
  fill: var(--primary-color);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hero-text {
  color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  margin-top: 50px;
}

.emerging-text {
  font-size: var(--h5-size);
  font-weight: bold;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.hero-more-button {
  border-radius: 10px;
  padding: 10px 40px;
  background-color: var(--primary-color);
  border: 0px;
  z-index: 1;
  margin-top: 20px;
  color: #000;
  font-weight: 700;
  transition: all 0.3s;
  cursor: pointer;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  overflow: hidden;
}

.hero-more-button:before {
  content: '';
  position: absolute;
  left: -20%;
  top: -10%;
  width: 0;
  height: 200%;
  transform: rotate(15deg) scale(1, 2);
  box-shadow: 0 0 30px 15px rgba(255, 255, 255, 0.7);
  animation: button-glow 3000ms ease-in-out infinite;
}

@keyframes button-glow {
  0% {
    left: -20%;
  }
  50% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

.hero-more-button:hover {
  transform: scale(1.03);
  background-color: #e6b800;
  color: #000;
}

.hero-more-button:active {
  transform: scale(0.98);
}

.main-text {
  font-size: var(--h4-size);
  font-weight: bold;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  word-break: keep-all;
}

@media (min-width: 2000px) {
  .hero-section {
    padding: 100px 350px;
  }
}

@media (min-width: 2500px) {
  .hero-section {
    padding: 100px 500px;
  }
}

@media (max-width: 1200px) {
  .hero-section {
    padding: 100px 100px;
  }

  .hero-logo {
    width: 60%;
  }
  .hero-img {
    object-position: 35% 50%;
  }
}

@media (max-width: 768px) {
  .hero-logo {
    width: 80%;
  }
  .hero-text {
    margin-top: 150px;
  }
}

@media (max-width: 556px) {
  .hero-section {
    padding: 10px;
    justify-content: center;
  }

  .hero-logo {
    width: 80%;
  }

  .hero-img {
    object-position: 30% 50%;
  }

  .hero-text {
    margin-top: 100px;
  }
}
