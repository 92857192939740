.program-wrapper {
  background-color: #000;
  padding: 1px 0px;
}

.program-list-container {
  background-color: #000;
}

.another-program-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  background-image: linear-gradient(
    180deg,
    var(--box-color) 10%,
    transparent 60%
  );
}
.anthor-program-footer-wrap {
  padding: 35px 0px;
  background-color: var(--primary-color);
  margin-bottom: 75px;
}

.anthor-program-footer-text {
  margin: 0px;
  color: #000;
  font-size: var(--h4-size);
  font-weight: bold;
  letter-spacing: 0.5%;
}

@media (max-width: 768px) {
  .anthor-program-footer-text {
    font-size: var(--h5-size);
  }
}

@media (max-width: 425px) {
  .anthor-program-footer-text {
    font-size: var(--h7-size);
  }
}
