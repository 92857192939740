.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background-color: white;
    padding: 30px 50px;
    border-radius: 8px;
    max-width: 1000px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
    z-index: 10000;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.modal-header button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}