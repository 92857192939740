.facility-text {
  position: relative;
  line-height: 1.8;
  margin-bottom: 50px;
  font-size: var(--h5-size);
  z-index: 1;
  word-break: keep-all;
}

.facility-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    var(--box-color) 10%,
    transparent 60%
  );
  z-index: 0;
}

.facility-text-title {
  position: relative;
  line-height: 1.8;
  margin-top: 50px;
  margin-bottom: 36px;
  z-index: 1;
  font-size: var(--h4-size);
  font-weight: bold;
}

.facility-text-content {
  position: relative;
  line-height: 1.8;
  z-index: 1;
  font-size: var(--h5-size);
  margin: 0px;
}

.facility-image {
  position: relative;
  width: 90%;
  height: auto;
  border-radius: 8px;
  z-index: 1;
  text-align: center;
}

.facility-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.custom-nav {
  padding: 20px;
  background-color: transparent;
}

.facility-sub-title {
  font-weight: bold;
  color: #fff;
  font-size: var(--h4-size);
}

.facility-sub-content {
  color: #606060;
  font-size: var(--h5-size);
  word-break: keep-all;
}

/* dots 컨테이너 스타일링 */
.facility-image .slick-dots {
  bottom: 25px;
  /* dots 위치 조정 */
}

/* 개별 dot 버튼 스타일링 */
.facility-image .slick-dots li button:before {
  color: #fff;
  opacity: 0.5;
  font-size: 12px;
}

/* 활성화된 dot 스타일링 */
.facility-image .slick-dots li.slick-active button:before {
  color: var(--primary-color);
  opacity: 1;
}

.facility-sub-image-wrap-left {
  display: flex;
  justify-content: left;
}

.facility-sub-image-wrap-right {
  display: flex;
  justify-content: right;
}

.facility-sub-image {
  width: 80%;
  height: auto;
  border-radius: 10px;
  z-index: 1;
  text-align: center;
}

.facility-sub-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.facilities-container {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 300px;
}

.facility-list-container {
  margin: 50px 0px;
}

.facility-text {
  font-size: 20px;
}

.facility-sub-text-left,
.facility-sub-text-right {
  display: flex;
  align-items: center;
}

.facility-sub-text-left {
  justify-content: right;
}

@media (max-width: 992px) {
  .facility-image {
    width: 100%;
  }

  .facility-sub-image {
    width: 90%;
  }

  .facility-sub-text-left,
  .facility-sub-text-right {
    padding: 0px;
  }
}

/* Medium devices (768px 이상) */
@media (max-width: 768px) {
  .facility-text-title {
    font-size: var(--h5-size);
  }
  .facility-text {
    font-size: 13px;
  }

  .facility-text-content {
    font-size: var(--h7-size);
  }

  .facility-sub-title {
    font-size: var(--h5-size);
  }

  .facility-sub-content {
    font-size: var(--h6-size);
  }

  .facility-sub-image-wrap-left,
  .facility-sub-image-wrap-right {
    justify-content: center;
  }

  .facility-sub-text-left,
  .facility-sub-text-right {
    margin-top: 20px;
    justify-content: left;
    text-align: start;
    width: 80%;
  }
}
