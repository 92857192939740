.sub-hero-section {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.sub-hero-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 컨테이너를 꽉 채우도록 설정 */
  z-index: 0; /* 다른 요소들 뒤에 위치하도록 설정 */
  text-align: center;
}

.sub-hero-main-text {
  font-size: var(--h2-size);
  color: #fff;
  font-weight: 800;
  font-style: italic;
  margin: 0px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.text-wrap {
  position: absolute;
  z-index: 1;
  text-align: center;
}

.sub-hero-sub-text {
  font-size: var(--h5-size);
  word-break: keep-all;
  color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
