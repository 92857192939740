.notice-container {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 10px 40px;
}

.notice-warp {
  padding: 50px 200px;
  margin: 30px 0px;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  background-color: #000000;
  border-radius: 15px;
  justify-content: center;
  width: 100%;
  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.98);
  }
}

.notice-item-warp {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: start;
}

.notice-title h3 {
  text-align: start;
  font-size: var(--h5-size);
  word-break: keep-all;
}

.notice-content {
  text-align: start;
  color: #606060;
  font-size: var(--h6-size);
  word-break: keep-all;
}

.notice-content p {
  margin: 0px;
}

.notice-item-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.notice-item-container a {
  color: inherit;
  text-decoration: none;
  width: 100%;
}

@media (min-width: 1025px) {
  .notice-container:hover .notice-warp:not(:hover) {
    filter: blur(1px);
    opacity: 0.8;
  }
}

@media (max-width: 1025px) {
  .notice-warp {
    padding: 50px 50px;
  }
}

@media (max-width: 768px) {
  .notice-container {
    padding: 10px 0px;
  }

  .notice-title h3 {
    font-size: var(--h5-size);
  }

  .notice-content {
    font-size: var(--h6-size);
  }
}

@media (max-width: 556px) {
  .notice-title h3 {
    font-size: var(--h6-size);
  }

  .notice-content {
    font-size: var(--h7-size);
  }

  .notice-warp {
    padding: 30px 20px;
  }
}
