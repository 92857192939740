.custom-slider-container {
  width: 100%;
}

.custom-slider-wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 비율 유지 */
  border-radius: 10px;
  z-index: 1;
}

.custom-slider-wrap .slick-slider,
.custom-slider-wrap .slick-list,
.custom-slider-wrap .slick-track {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-slider-wrap .slick-slide {
  position: relative;
  height: 100%;
}

.custom-slider-wrap .slick-slide > div {
  height: 100%;
  display: flex;
  justify-content: center;
}

.custom-slider-wrap-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.custom-slider-wrap .slick-dots {
  bottom: 20px;
}

.custom-slider-wrap .slick-dots li button::before {
  font-size: 12px !important;
  color: white !important;
  opacity: 1;
}

.custom-slider-wrap .slick-dots li.slick-active button::before {
  color: var(--primary-color) !important;
  opacity: 1;
}

@media (width: 768px) {
  .custom-slider-wrap .slick-dots li button::before {
    font-size: 10px !important;
  }
}

@media (max-width: 576px) {
  .custom-slider-wrap .slick-dots li button::before {
    font-size: 7px !important;
  }
}

/* .slick-slider .slick-arrow.custom-arrow-next {
  z-index: 10;
  font-size: 50px;
  color: var(--primary-color);
  padding: 25px;
  border-radius: 50%;
  display: block !important;
}

.slick-slider .slick-arrow.custom-arrow-next::before {
  content: none !important;
}

.slick-slider .slick-arrow.custom-arrow-prev {
  z-index: 10;
  font-size: 50px;
  color: var(--primary-color);
  padding: 25px;
  border-radius: 50%;
  display: block !important;
}

.slick-slider .slick-arrow.custom-arrow-prev::before {
  content: none !important;
}

.slick-slider .slick-arrow.custom-arrow-prev:hover,
.slick-slider .slick-arrow.custom-arrow-next:hover {
  color: #c9ad4b !important;
} */
