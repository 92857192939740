.navbar-custom {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  background-color: black;
}

.navbar-custom.hidden {
  opacity: 0;
  visibility: hidden;
}

.navbar .navbar-brand {
  margin-right: 10px;
  cursor: pointer;
}

.navbar-brand {
  padding-left: 48px;
}

.navbar-nav {
  margin-left: 0;
  padding-right: 48px;
}

.nav-link {
  position: relative;
  margin: 0px 2px;
  color: #fff;
}

.nav-link:hover {
  color: var(--primary-color);
}

@media (max-width: 991px) {
  .navbar-container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .navbar-brand {
    padding-left: 0px;
  }

  .nav-link:hover::after,
  .nav-link.active::after {
    width: 100%;
  }
}
