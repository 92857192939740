.news-wrapper {
  background-color: #000;
  color: white;
  min-height: 100vh;
  width: 100%;
  padding: 1px 0px;
}

.news-title {
  font-size: var(--h2-size);
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin-top: 105px;
  margin-bottom: 88px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.custom-hr {
  border: none;
  width: 100%;
  height: 3px;
  background-color: #0f2a71;
}

.news-list-wrap {
  padding: 60px 20px;
}

.accordion {
  width: 100%;
  padding: 60px 80px;
}

.accordion-item {
  border: 3px solid #1162a2;
  margin-bottom: 50px;
  border-radius: 10px;
  overflow: hidden;
}

.accordion-item-hr {
  border: none;
  width: 100%;
  height: 2px;
  background-color: #1162a2;
}

.accordion-header {
  padding: 15px 50px;
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-title-wrap {
  display: flex;
  align-items: center;
  position: relative;
}

.accordion-category-wrap {
  text-align: start;
}

.accordion-category {
  color: #1162a2;
  font-size: var(--h5-size);
}

.accordion-date {
  color: #606060;
  font-size: var(--h7-size);
  margin: 0px;
}

.accordion-item-title {
  font-size: var(--h4-size);
  font-weight: bold;
  margin: 0px 0px 0px 55px;
}

.red-ellipse {
  position: absolute;
  top: 7px;
  right: -15px;
}

.accordion-header.active {
  background: #fff;
  padding: 15px 50px 0px;
}

.accordion-content {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.35s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 0 15px;
}

.accordion-content.open {
  max-height: 500px;
  opacity: 1;
  padding: 32px 15px 15px;
}

.arrow {
  font-size: 12px;
}

.accordion-content-text {
  margin-top: 28px;
  font-size: var(--h6-size);
  line-height: 200%;
  padding: 0px 35px;
  text-align: start;
}

@media (max-width: 992px) {
  .accordion {
    padding: 60px 10px;
  }
}

@media (max-width: 768px) {
  .accordion-category {
    font-size: var(--h7-size);
  }

  .accordion-item-title {
    font-size: var(--h6-size);
  }
}

@media (max-width: 510px) {
  .accordion-header {
    padding: 15px 20px;
  }

  .accordion-item-title {
    font-size: var(--h7-size);
    margin-left: 20px;
  }

  .accordion-category {
    font-size: 10px;
  }
  .accordion-date {
    font-size: 10px;
  }
  .news-title {
    font-size: var(--h4-size);
  }

  .red-ellipse img {
    width: 5px;
    height: 5px;
  }

  .red-ellipse {
    position: absolute;
    top: 7px;
    right: -10px;
  }

  .accordion-content-text {
    padding: 0px 10px;
    font-size: var(--h7-size);
  }
  .accordion-header.active {
    background: #fff;
    padding: 15px 20px 0px;
  }
}
