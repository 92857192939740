.custom-image-slider-container {
  width: 90%;
}

.custom-image-slider-wrap {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 비율 유지 */
  border-radius: 10px;
  z-index: 1;
}

.custom-image-slider-wrap .slick-slider,
.custom-image-slider-wrap .slick-list,
.custom-image-slider-wrap .slick-track {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-image-slider-wrap .slick-slide {
  position: relative;
  height: 100%;
}

.custom-image-slider-wrap .slick-slide > div {
  height: 100%;
}

.custom-image-slider-wrap-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.image-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
}

.image-dot {
  margin: 0 5px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.image-dot.active {
  opacity: 1;
  border: 1px solid var(--primary-color);
}

.image-dot img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
