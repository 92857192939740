.program-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(0deg, var(--box-color) 20%, transparent 120%);
  z-index: 0;
}

.program-section > * {
  position: relative;
  z-index: 1;
}

.program-wrap {
  display: flex;
  gap: 80px 20px;
  padding: 16px;
  justify-content: center;
  flex-flow: row wrap;
}

/* Program container styling */
.program-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  padding: 0px;
  max-width: 363px;
  width: 100%;
  height: 100%;
  transition: all 0.3s;

  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.98);
  }
}

.program-image {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.program-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-text {
  color: var(--primary-color);
  font-size: 35px;
  font-weight: bold;
}

.blur-image img {
  filter: blur(5px);
}

.program-content {
  text-align: start;
  background-color: #000;
  padding: 38px 26px 15px;
  border-radius: 0px 0px 10px 10px;
}

.program-title {
  font-size: var(--h5-size);
  font-weight: bold;
}

.program-description {
  font-size: var(--h6-size);
  height: 100px;
  color: #606060;
  word-break: keep-all;
}

.program-link {
  color: #c9ad4b;
  text-decoration: none;
  font-weight: bold;
}

.program-link:hover {
  text-decoration: none;
}

@media (max-width: 768px) {
  .program-wrap {
    padding: 16px 0px;
  }

  .overlay-text {
    font-size: 25px;
  }

  .program-title {
    font-size: var(--h6-size);
  }

  .program-description {
    font-size: var(--h7-size);
    height: 70px;
  }

  .program-link {
    font-size: var(--h7-size);
  }
}
