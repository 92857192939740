.footer-section {
  background-color: #001141;
  padding: 40px 60px;
}

.footer-title-color {
  color: var(--primary-color);
  text-decoration: none;
  font-size: var(--h7-size);
  font-weight: bold;
  letter-spacing: -3%;
}

.footer-content-color {
  color: var(--content-color);
  font-size: var(--h7-size);
}

.footer-content-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.footer-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-logo img {
  height: 40px;
}

.footer-logo p {
  margin: 0;
}

.footer-sns-link {
  display: flex;
  gap: 20px;
}

.footer-sns-link a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .footer-section {
    padding: 20px 20px;
  }
}

.logo-circle {
  width: 30px;
  height: 30px;
  background: #0a1744;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo-inner {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-image {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.company-sitemap {
  display: none;
  /* 기본적으로 숨김 */
}

@media (min-width: 992px) {
  .company-sitemap {
    display: block;
    /* 992px 이상에서만 보이게 함 */
  }
}
