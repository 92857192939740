.main-sponsored-section {}

.sub-sponsor-banner {
    width: 100%;
    background: #000;
    overflow: hidden;
    padding: 0 0 20px 0;
}

.sub-sponsor-track {
    display: flex;
    animation: slide 30s linear infinite;
    white-space: nowrap;
}

.sub-sponsor-logo {
    height: 40px;
    filter: brightness(0.7);
    transition: filter 0.3s;
    margin: 0 40px;
}

.main-sponsor-wrap {
    width: 100%;
    background-color: #FFF;
    padding: 40px 0px;
}

.main-sponsor-wrap img {
    width: 100%;
    max-width: 180px;
    height: auto;
    margin-right: 10px;
    margin-left: 10px;
}

.sparkle {
    color: #0067b1;
}

@keyframes slide {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-40%);
    }
}

/* 태블릿 크기 */
@media screen and (max-width: 768px) {
    .sub-sponsor-logo {
        margin: 0 15px;
        height: 35px;
        animation: slide 30s linear infinite;
    }

    .main-sponsor-wrap h2 {
        font-size: 24px;
        /* 헤더 텍스트 크기 감소 */
    }

    .main-sponsor-wrap img {
        max-width: 150px;
        /* 이미지 최대 너비 감소 */
    }

    .main-sponsor-wrap p {
        font-size: 20px !important;
        /* 문단 텍스트 크기 감소 */
    }

    @keyframes slide {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-50%);
        }
    }
}

/* 모바일 크기 */
@media screen and (max-width: 375px) {
    .sub-sponsor-logo {
        margin: 0 10px;
        height: 30px;
    }

    @keyframes slide {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-60%);
        }
    }
}